import * as _ from "lodash";
import { pathJoin } from "../Utils"
import { IApi } from "./IApi";

export class Api implements IApi {

    constructor(private readonly baseUrl: string) {
        // Prep the base url so we can always just concat it with the endpoint later
        this.baseUrl = _.trim(this.baseUrl.trim(), "/") + "/";
    }

    // Convenience methods for particular requests
    async postAsync<T>(endpoint: string, data: any, config?: RequestInit): Promise<T> {
        var merged = Object.assign({
            body: JSON.stringify(data),
            method: 'POST'
        }, config);

        return this.fetchApiData<T>(endpoint, merged);
    }

    async getAsync<T>(endpoint: any, config?: RequestInit): Promise<T> {
        var merged = Object.assign({
            method: 'GET'
        }, config);

        return this.fetchApiData<T>(endpoint, merged);
    }

    async deleteAsync<T>(endpoint: any, config?: RequestInit): Promise<T> {
        var merged = Object.assign({
            method: 'DELETE'
        }, config);

        return this.fetchApiData<T>(endpoint, merged);
    }

    async putAsync<T>(endpoint: string, data: any, config?: RequestInit): Promise<T> {
        var merged = Object.assign({
            body: JSON.stringify(data),
            method: 'PUT'
        }, config);

        return this.fetchApiData<T>(endpoint, merged);
    }

    async uploadFormDataAsync(formData: FormData, endpoint: string): Promise<any> {
        var data: RequestInit = {
            method: "POST",
            body: formData,
        };

        let url = pathJoin(window.location.origin, this.baseUrl, endpoint);
        let response = await fetch(url, data);

        this.checkForHttpSuccess(response);
        let json = await response.json() as ApiResponse<any>;
        return json.data;
    }

    async getUnauthorizedAsync<T>(endpoint: any, config?: RequestInit): Promise<T> {
        var merged = Object.assign({
            method: 'GET'
        }, this.standardHeadersAndCreds(), config);

        return this.fetchApiData<T>(endpoint, merged);
    }

    async postUnauthorizedAsync<T>(endpoint: string, data: any, config?: RequestInit): Promise<T> {
        var merged = Object.assign({
            body: JSON.stringify(data),
            method: 'POST'
        }, this.standardHeadersAndCreds(), config);

        return this.fetchApiData<T>(endpoint, merged);
    }

    async deleteUnauthorizedAsync<T>(endpoint: string, data: any, config?: RequestInit): Promise<T> {
        var merged = Object.assign({
            body: JSON.stringify(data),
            method: 'DELETE'
        }, this.standardHeadersAndCreds(), config);

        return this.fetchApiData<T>(endpoint, merged);
    }

    private standardHeadersAndCreds(): Partial<RequestInit> {
        return {
            credentials: 'same-origin',
            headers: new Headers({
                "Content-Type": "application/json",
            })
        };
    }

    async fetchApiData<T>(endpoint: string, config?: RequestInit): Promise<T> {
        let response = null;
        try{
            response = await this.sendAsync(endpoint, config);
        }catch(e){
            let error = e;
            console.log(e);
        }
        if(!response){
            throw new NetError(`There was a an api call with no response`, 404);
        }
        this.checkForHttpSuccess(response);

        let json = await this.deserializeAsync<T>(response);
        this.checkForApiSuccess(json);

        return json.data;
    }

    // No processing, just send the request.
    async sendAsync(endpoint: string, config?: RequestInit): Promise<Response> {
        let merged = {};
        _.merge(merged, config);

        let path = pathJoin(window.location.origin, this.baseUrl, endpoint);
        return await fetch(path, merged);
    }

    private checkForHttpSuccess(response: Response): Response {
        if (response.status != 200) {
            throw new NetError(`There was a network error with code: ${response.status}`, response.status);
        }
        return response;
    }

    private checkForApiSuccess<T>(json: ApiResponse<T>): ApiResponse<T> {
        if (!json.success) {
            throw new ApiError(json.errorMessage, json.errorCode, json.errorNamespace, json.errorDetails);
        }
        return json;
    }

    private async deserializeAsync<T>(response: Response): Promise<ApiResponse<T>> {
        return await response.json() as ApiResponse<T>;
    }
}

//#region Helper Classes

class ApiResponse<T> {
    constructor(
        readonly success: boolean,
        readonly errorNamespace: string,
        readonly errorCode: string,
        readonly errorMessage: string,
        readonly errorDetails: string,
        readonly data: T
    ) {

    }
}

// 200 OK but with no data
export class ApiError {

    constructor(
        readonly message: string,
        readonly code: string,
        readonly namespace: string,
        readonly details: string
    ) {

    }

}

// 404s and such
export class NetError {
    constructor(
        readonly message: string,
        readonly code: number
    ) {

    }
}

export class JwtMissing {
    constructor(
        readonly message: string
    ) {

    }
}

//#endregion Helper Classes