import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { SearchTrayComponent } from "../UI/SearchTrayComponent";
import { ProjectCardComponent } from "./ProjectCard/ProjectCardComponent";

interface IProps {
    pageTitle:string;
}

interface IState {
    chosen:string;
}

export class ProjectsComponent extends React.Component<IProps, IState> {
    
    constructor(props:IProps) {
        super(props);
        this.state={chosen:''};
    }

    
    selectCallback=(selectedText:string) => {
        this.setState({chosen:selectedText});
    }

    render() {
        let searchList = ["Project #1","Project #2","Project #3","Project #4","Project #5","Project #6"];
        let hasChosen = this.state.chosen.length > 0;
        let title = this.state.chosen.length > 0?this.state.chosen : "Please select an item"
        return(
            <Grid item container direction={'row'} spacing={0}>
                <SearchTrayComponent trayTitle={''} searchList={searchList} selectCallback={this.selectCallback}/>
                <Grid item container xs>
                { hasChosen && (
                        <ProjectCardComponent title={title} />
                    )}
                    { !hasChosen && (
                        <Typography variant='h4'>
                            {title}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        )
    }
}