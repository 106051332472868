import React from "react";
import {CircularProgress, Grid, Typography, Button, Menu, MenuItem} from "@material-ui/core";
import { LogicProvider } from "../Logic/LogicProvider";
import {observer} from "mobx-react";
import { LoadingComponent } from "../Loading/LoadingComponent";
import { NavLink, Link } from "react-router-dom";

interface IProps {
    children?: any;
    logic: LogicProvider;
}

interface IState {
}

@observer
export class NavigationComponent extends React.Component<IProps, IState> {
    
    constructor(props:IProps) {
        super(props);
    }

    render() {
        let loading = this.props.logic.store.contentLoading;
        let children = this.props.children;

        return(
            <Grid container item direction='row' xs={12} style={{height:'100%', width:'100%'}}>
                
                <Grid container item direction='column' xs={1} spacing={0} style={{backgroundColor:'#1f1e1e', color:'#bac4e3' }} >
                    <Grid item style={{paddingBottom:'1rem'}}>
                        <Typography variant='h4'>Fluid</Typography>
                    </Grid>
                    <Grid item component={Link} to={'/home'} style={{textDecoration: 'none'}}>
                        <Button>
                            <Typography variant='caption' style={{color:'#bac4e3' }}>
                                Home
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item component={Link} to={'/projects'} style={{textDecoration: 'none'}}>
                        <Button>
                            <Typography variant='caption' style={{color:'#bac4e3' }}>
                                Projects
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item  component={Link} to={'/parts'} style={{textDecoration: 'none'}}>
                        <Button>
                            <Typography variant='caption' style={{color:'#bac4e3' }}>
                                Parts
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item  component={Link} to={'/debug'} style={{textDecoration: 'none'}}>
                        <Button>
                            <Typography variant='caption' style={{color:'#bac4e3' }}>
                                Site Status
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs />
                </Grid>
                <Grid container item direction='row' xs>
                    {loading && (
                        <Grid container item direction='row' style={{textAlign:'center'}}>
                            <LoadingComponent />
                        </Grid>
                    )}
                    
                    {!loading && 
                        children
                    }
                </Grid>
            </Grid>
        )
    }
}