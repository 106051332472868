import * as _ from 'lodash';

export const pathJoin = (...parts: string[]): string => {
    if (!parts) {
        return "";
    }

    if (parts.length == 1) {
        return parts[0];
    }
    let finalUrlString = "";
    parts.forEach((part) => {
        if(finalUrlString.length < 1){
            finalUrlString = new URL(part).toString();
        }else{
            finalUrlString = new URL(part, finalUrlString).toString();
        }
    });
    return finalUrlString;

    
}

export const sleepOnIt = async(milliseconds: number) => {
    new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const waitForData = async(data: any[], retryLimit: number = 10): Promise<boolean> => {
    await sleepOnIt(200);
    let retryCounter: number = 0;
    while(retryCounter <= retryLimit && (data.includes(null))){
        console.log("no data found, waiting for data to load. (" + (retryLimit - retryCounter) + ") retries remaining.");
        await sleepOnIt(200);
        retryCounter++;
        if(retryCounter >= retryLimit){
            return false;
        }
    }
    return true;
}