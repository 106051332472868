import React from "react";
import { Grid, Typography } from "@material-ui/core";

interface IProps {
    pageTitle:string;
}

interface IState {
}

export class HomeComponent extends React.Component<IProps, IState> {
    
    constructor(props:IProps) {
        super(props);
    }

    render() {
        return(
            <Grid item alignContent={'center'} xs>
                <Typography variant='h4'>
                    {this.props.pageTitle}
                </Typography>
                This could be a place to show a list of recent projects/parts/activity, or other dashboard type information. We could also show news or messages pertaining to the user's account.
            </Grid>
        )
    }
}