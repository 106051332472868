import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { HomeComponent } from '../Home/HomeComponent';
import { DebugComponent } from '../Debug/DebugComponent';
import { LogicProvider } from '../Logic/LogicProvider';
import { NavigationComponent } from '../Navigation/NavigationComponent';
import { PartsComponent } from '../Parts/PartsComponent';
import { ProjectsComponent } from '../Projects/ProjectsComponent';

interface IProps {
    logic: LogicProvider;
}

export class AppRouter extends React.PureComponent<IProps> {
    constructor(props: IProps) {
        super(props);
    }

    public renderRoutes() {
        // let { logic, store } = this.props;

        return (
            <Router>
                <Switch>
                <Route path="/debug">
                    <NavigationComponent logic={this.props.logic}>
                        <DebugComponent logic={this.props.logic}/>
                    </NavigationComponent>  
                </Route>
                <Route path="/home">
                    <NavigationComponent logic={this.props.logic}>
                        <HomeComponent pageTitle={"Home"} />
                    </NavigationComponent>  
                </Route>
                <Route path="/projects">
                    <NavigationComponent logic={this.props.logic}>
                        <ProjectsComponent pageTitle={"Projects"} />
                    </NavigationComponent>  
                </Route>
                <Route path="/parts">
                    <NavigationComponent logic={this.props.logic}>
                        <PartsComponent pageTitle={"Parts"} />
                    </NavigationComponent>  
                </Route>
                <Route path="/">
                    <NavigationComponent logic={this.props.logic}>
                        <HomeComponent pageTitle={"Home"} />
                    </NavigationComponent>  
                </Route>
                </Switch>
            </Router>
        );
    }
    public render() {
        return this.renderRoutes();
    }

}