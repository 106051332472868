import { IDataStore } from "../Data/DataStore";
import { IApi } from "../Api/IApi";
import { IApiStatusResponse } from "../Data/Models/ApiStatusResponse";
import { sleepOnIt } from "../Utils";
import _ from 'lodash';

export class LogicProvider {
    public store: IDataStore;
    private _api: IApi;

    constructor(
        appApi: IApi,
        store: IDataStore
    ) {
        this._api = appApi;
        this.store = store;
    }

    public contentLoading(isLoading?:boolean){
        if(isLoading != undefined){
            this.store.contentLoading = isLoading;
        }
        return this.store.contentLoading;
    }

    public async updateApiStatusAsync(): Promise<void> {
        this.contentLoading(true);
        await this.apiStatusAsync().then(status => {
            this.store.apiStatus = status;
            setTimeout(()=>{ this.contentLoading(false); }, 1000);
        });
    }

    public async apiStatusAsync(): Promise<IApiStatusResponse> {
        let status = await this._api.getAsync<IApiStatusResponse>(`status/now`);
        return status;
    }
}