import React from "react";
import { Grid, Typography, Drawer, Button, TextField, IconButton } from "@material-ui/core";
import {Pageview, ArrowForwardIos, ArrowBackIos} from '@material-ui/icons/';
import _ from 'lodash';

interface IProps {
    trayTitle:string;
    searchList:string[];
    selectCallback:(selectedText:string)=>void;
}

interface IState {
    openDrawer:boolean;
    searchText:string;
}

export class SearchTrayComponent extends React.Component<IProps, IState> {
    
    constructor(props:IProps) {
        super(props);
        this.state={openDrawer:false,searchText:''};
        
    }

    toggleDrawer = () => {
        let isOpen = this.state.openDrawer;
        this.setState({openDrawer:!isOpen});
    }

    searchChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
        
        let newVal = evt.target.value;
        this.setState({searchText:newVal});
    }

    render() {
        let drawerOpen = this.state.openDrawer;
        let drawerWidth: 1 = 1;
        let filteredList = this.props.searchList.filter(
                itm => this.state.searchText.length == 0 || 
                itm.toLowerCase().includes(this.state.searchText.toLowerCase())
            );
        let listResults = filteredList.map((itm, idx) => (
                <Grid item key={itm+idx} onClick={()=>this.props.selectCallback(itm)}>
                    {itm}
                </Grid>
            ));

        return(
                <Grid item container direction={'column'} xs={drawerWidth} >
                    {
                        drawerOpen && (<Grid item>
                            <Button onClick={()=>this.toggleDrawer()} hidden={drawerOpen}><Pageview/><ArrowForwardIos/></Button>
                        </Grid>)
                    }
                    <Grid item container direction={'column'} alignContent={'flex-start'} style={{visibility: drawerOpen?'hidden':'visible'}} xs>
                        <Grid item>
                            <Button onClick={()=>this.toggleDrawer()} ><ArrowBackIos/><Pageview/></Button>
                        </Grid>
                        <Grid item >
                            <TextField id="filled-search" label="Find" type="search" variant="filled" onChange={this.searchChanged} />
                        </Grid>
                        {
                            listResults
                        }
                    </Grid>
                </Grid>
                
        )
    }
}