import { pathJoin } from "./Utils";

export class AppConfig implements IAppConfig {
    public readonly siteBaseUrl: string;
    public readonly apiBaseUrl: string;

    constructor(config: IAppConfig) {
        this.siteBaseUrl = config.siteBaseUrl;
        this.apiBaseUrl = config.apiBaseUrl;
    }

    // Return a url based on the siteBaseUrl for the partial url provided
    public url(partial: string) {
        return pathJoin(this.siteBaseUrl, partial);
    }
}