import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";

interface IProps {
}

interface IState {
}

export class LoadingComponent extends React.Component<IProps, IState> {
    
    constructor(props:IProps) {
        super(props);
    }

    render() {

        return(
            <Grid item xs>
                <CircularProgress />
            </Grid>
        )
    }
}