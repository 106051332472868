import React from "react";
import { Card, CardContent, Typography, CardActions, Button } from "@material-ui/core";

interface IProps {
    title:string,
    details:string,
}

interface IState {
    chosen:string,
}

export class ItemCardComponent extends React.Component<IProps, IState> {
    
    constructor(props:IProps) {
        super(props);
        this.state={chosen:''};
    }

    selectCallback=(selectedText:string) => {
        this.setState({chosen:selectedText});
    }

    render() {
        return(
            <Card>
                <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                        Name
                    </Typography>
                    <Typography variant="h5" component="h2">
                        {this.props.title}
                    </Typography>
                    <Typography color="textSecondary">
                        Details
                    </Typography>
                    <Typography variant="body2" component="p">
                        {this.props.details}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions>
            </Card>
        )
    }
}