
import {observable} from 'mobx'
import { IApiStatusResponse } from './Models/ApiStatusResponse';
export interface IDataStore {
    contentLoading: boolean,
    apiStatus?: IApiStatusResponse,
}

export class DataStore implements IDataStore {
    @observable
    public contentLoading: boolean = false;

    @observable
    public apiStatus?: IApiStatusResponse = undefined;
}