import React from "react";
import { Grid, Button } from "@material-ui/core";
import { LogicProvider } from "../Logic/LogicProvider";
import { IApiStatusResponse } from "../Data/Models/ApiStatusResponse";
import { observer } from "mobx-react";

interface IProps {
    logic: LogicProvider,
}

interface IState {
}

@observer
export class DebugComponent extends React.Component<IProps, IState> {
    
    constructor(props:IProps) {
        super(props);
    }

    render() {
        let {apiStatus} = this.props.logic.store;
        let siteStatus = "Active";
        let apiStatusText = "Inactive";
        let apiTimeStamp = "Never";
        if(apiStatus){
            apiStatusText = apiStatus.status;
            apiTimeStamp = apiStatus.timeStamp;
        }
        return(
            <Grid container item direction={'column'}>
                <Grid item>
                    Website Status: {siteStatus}
                </Grid>
                <Grid item>
                    Api Status: {apiStatusText}
                </Grid>
                <Grid item>
                    Last Checked: {apiTimeStamp}
                </Grid>
                <Grid item>
                    <Button onClick={async ()=> {await this.props.logic.updateApiStatusAsync()}}>Refresh</Button>
                </Grid>
            </Grid>
        )
    }
}