import React from "react";
import { Card, CardContent, Typography, CardActions, Button } from "@material-ui/core";
import { ItemCardComponent } from "../../UI/ItemCardComponent";

interface IProps {
    title:string;
}

interface IState {
    chosen:string;
}

export class PartCardComponent extends React.Component<IProps, IState> {
    
    constructor(props:IProps) {
        super(props);
        this.state={chosen:''};
    }

    selectCallback=(selectedText:string) => {
        this.setState({chosen:selectedText});
    }

    render() {
        let itemDetails = "Information about the chosen part would be displayed here. Things like measurements, material, or possibly other details about it's origin or usage in projects.";
        return(
            <ItemCardComponent title={this.props.title} details={itemDetails}/>
        )
    }
}