import React from 'react';
import logo from './logo.svg';
import './App.css';
import { AppRouter } from './Routing/Routing';
import { LogicProvider } from './Logic/LogicProvider';
import { DataStore } from './Data/DataStore';
import { ThemeProvider } from '@material-ui/core';
import { appTheme } from './Theme/AppTheme';
import { AppConfig } from './AppConfig';
import { Api } from './Api/Api';

function App() {
  Config.apiBaseUrl = '/api/1';
  const config = new AppConfig(Config);
  const appApi = new Api(Config.apiBaseUrl);
  const dataStore = new DataStore();
  var logic = new LogicProvider(appApi, dataStore);

  return (
    <div className="App">
      <ThemeProvider theme={appTheme}>
        <AppRouter logic={logic} />
      </ThemeProvider>
    </div>
  );
}

export default App;
